.kw-table__row {
  padding: 16px 10px;
  &:not(:last-child) {
    border-bottom: solid 1px #f0f3ff;
  }
}
.kw-table__row-title {
  font-weight: 400;
}
.kw-table__head-title {
  font-weight: 600;
}
