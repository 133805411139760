@import '../../../../../public/variables';

.w-title {
  h5 {
    padding: 20px;
  }
}

.w-true {
  border-bottom: solid 2px $brand;
}
