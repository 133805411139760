//brand colors
$brand: #a044ff;
$lightBg: #e6e9e8;
$gray: #646c9a;
$gray2: #2C3652;
$drive: #02b7ff;

//misc colors
$highlighter: #FFFF1F;
$textLight: #a7abc3;

//primarily for dark theme
$peach: #FF8867;
$pink: #FF7388;
$hotPink: #EC4690;
$portletBg: linear-gradient(to bottom right, #1E2541, #2E3350);
$orange: #ffa72d;
$fbBlue: #036EE9;
$darkBg: #16192E;
$darkBg2: #1E1D2D;
$darkBgLighter: #2B314E;
$lightBlue: #53D1DC;

//bootstrap colors
$warning: #ffb822;
$danger: #fd397a;
$success: #0abb87;
$default: #93a2dd;
$info: #36a3f7;

// semrush
$semGreen: #08bb87;
$semRed: #fd397a;
$semOrange: #ffb822;
$semLightgray: #93a2dd;
$semDarkgray: #a044ff;

// misc
$shadow: 0 4px 4px -4px $gray;
$shadowCenter: 0 4px 4px -4px $gray;