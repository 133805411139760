.data-option {
  border: 1px solid transparent;
  border-radius: 6px;
  background: #f4f6f9;
  cursor: pointer;
  text-align: center;
  padding: 3rem;
  margin-bottom: 3rem;

  i {
    font-size: 4rem;
    margin-bottom: 5px;
  }

  h5 {
    font-weight: bold !important;
    letter-spacing: 1.2px;
  }
}

.active {
  border-color: black;
}
