.kt-datatable {
  width: 100% !important;

  td {
    max-width: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}
