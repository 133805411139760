// this file painfully overwrites metronic theme colors with marketing milk color
@import '../variables';

.m-wizard.m-wizard--5.m-wizard--primary .m-wizard__head .m-wizard__steps .m-wizard__step.m-wizard__step--current {
  border-bottom: 2px solid $brand ;
}

// separated for readability
.m-wizard.m-wizard--5.m-wizard--primary .m-wizard__head .m-wizard__steps .m-wizard__step.m-wizard__step--current .m-wizard__step-info .m-wizard__step-number .m-wizard__step-label {
  color: $brand;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-icon {
  color: $brand
}
.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-username {
  color: $brand;
}
//

.btn-primary {
  background: $brand;
}
.btn-primary:hover {
  background-color: lighten($brand, 5);
}
.btn-brand, .btn.btn-brand, button.btn-brand {
  padding: .6em 2em;
  font-size: 12px;
  border: solid 1px $brand;
  transition-duration: .25s;
  border-radius: 0.25rem;
  background-color: $brand !important;
}
.btn-brand:hover{
  background-color: darken($brand, 10) !important;
}
.btn-help {
  position: absolute;
  top: 1.3rem;
  right: 2rem;
  z-index: 10;
  display: block;
  width: fit-content;
  background-color: $brand;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  i {
    font-size: 13px;
  }
}
