@import '../variables';

.click-copy{
  cursor: copy;
  background-color: rgba($brand, 0.1);
  border: 1px solid $brand;
  border-radius: 5px;
  padding: 0 5px;
}
.click-copy:active{
  background-color: rgba($brand, 0.3);
}

._loading_overlay_wrapper {
  padding: 15px;
}
._loading_overlay_overlay {
  border-radius: 5px;
}

._loading_overlay_content {
  font-weight: 600;
}