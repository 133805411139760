.submission-warning {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: rgb(84, 84, 84);
  font-size: 1.125em;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  overflow-wrap: break-word;
}
