@import './modules/index';

body {
  background: #f2f3f8;
}
.ticket-thread {
  padding: 15px;
  &:hover {
    background: #fafbfc;
  }
}
.overflowY-scroll {
  overflow-y: scroll;
}

.fit-content {
  width: fit-content;
}

.ticket-thread__active {
  background: #fafbfc;
}

// content padding override to match builder
.kt-content {
  padding: 15px !important;
}

.center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.privacy-popup{
  padding: 50px;
  position: fixed;
  top: 10%;
  left: 30%;
  background-color: #0d4d71;
  color: #fff;
  z-index: 1;
  border-radius: 5px;
}

.portlet-as-button {
  cursor: pointer;
  transition: .2s;
}
.portlet-as-button:hover {
  margin-top: -10px;
}
.bg-brand {
  background-color: $brand !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.kt-notes__content:hover .kt-notes__title {
  color: $brand !important;
}
.h100 {
  height: 100px;
}

.accordian-card-body-wrap {
  border-left: 1px solid $success;
  padding: 0px 0px 5px 0px;
  margin-left: 6px;
  margin-bottom: 10px;
}
.kt-notes__icon:hover {
  background-color: lighten($brand, 10%) !important;
  transition: .2s;
  i {
    color: white !important;
    transition: .2s;
  }
}
.btn i {
  padding-right: 0;
}
html, body {
  color: #575962;
}

.Select-menu-outer {
  border: solid 1px black !important;
}
.accordion .card-body {
  margin-left: 6px !important;
  padding-left: 20px !important;
  border-left: solid 1px #5d78ff;
}
.accordion .card {
  overflow: visible !important;
}
.kt-wizard-v2 .kt-wizard-v2__aside {
  display: block !important;

}
a:hover > i {
  color: $brand
}

.btn-empty {
  background: none;
  border: none;
}
.kt-aside__brand-logo {
  margin: 0 auto;
  font-size: 3rem;
}

.btn-empty {
  background: transparent;
  border: none;
  cursor: pointer;
}

.btn-empty:focus {
  outline: 0;
}

.same-height {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}

.no-overflow {
  overflow: hidden;
}

.blue-bar {
  height: 4px;
  width: 100%;
  background-color: #dee1eb;
  position: relative;
}

.flex-box {
  display: flex;
  width: 100%;
  height: 100px;

  .same-width {
    margin: 0 auto;
    flex-basis: 100%;
  }

  .kt-widget__item {
    display: -webkit-box !important;
  }

}

.kt-widget__hover {
  opacity: 0.8;
}

.kt-widget__hover:hover {
  opacity: 1.0;
}

.kt-aside__brand-logo img {
  height: 55px;
}
#kt_aside::-webkit-scrollbar { width: 0 !important }

#kt_aside {
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.kt-aside-menu .kt-menu__nav {
  padding: 0;
}
.kt-aside .kt-aside-menu {
  margin: 0;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__section:first-child {
  margin: 0;
}
#kt_aside_brand {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 265px;
}
#kt_aside_menu_wrapper {
  padding-top: 75px;
}
[data-react-beautiful-dnd-droppable="0"] {
  min-height: 400px;
}

.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic {
  height: 60px;
}

.kt-widget__item {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kt-widget__hover {
  opacity: 0.8;
}

.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-widget__title {
  font-size: 1.5rem;
  color: #434349 !important;
  font-weight: 400;
  text-transform: uppercase;
}

.hover-brand:hover {
  color: $brand !important;
}
.help-note {
  font-size: 0.8em;
  background-color: wheat;
  padding: 5px;
}

.bb {
  border-bottom: solid 1px #edeeef;
}
.action-button {
opacity: .7 !important;
}

.action-button:hover {
  opacity: 1 !important;
  & * {
    color: $info !important;
    opacity: 1 !important;
  }
}

/*spinner*/
/* ENDLESS ROTATE */
.rotate{
  -webkit-animation: rotate 2s linear infinite !important;
  animation: rotate 1.5s linear infinite !important;
}
@-webkit-keyframes rotate{ to{-webkit-transform: rotate(360deg); } }
@keyframes         rotate{ to{        transform: rotate(360deg); } }
