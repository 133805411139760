.submission-dark-card {
  .kt-form,
  .kt-wizard-v2 .kt-wizard-v2__wrapper {
    background: none;
    color: black;
  }

  background: #fff;
  border: 2px solid transparent;
  border-right: 6px;
  color: white;
  cursor: pointer;
  padding: 5px;
}
